import React, { useEffect, useState } from "react";

import { API, graphqlOperation, Storage } from "aws-amplify";

import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

export default function CardWriting() {
	const navigate = useNavigate();
	const location = useLocation();

    const [type, setType] = useState(0);
	const [content, setContent] = useState("");
	const [placeholder, setPlaceholder] =
		useState("여기를 눌러\n내용을 입력하세요.");

	const [popup, setPopup] = useState(false);

	const [clicked, setClicked] = useState(false);

	function openPopup() {
		setPopup(true);
	}

	function closePopup() {
		setPopup(false);
	}

	async function send() {
		if (!content) {
			return;
		}

		if (clicked) {
			return;
		}

		setClicked(true);

		const apiInit = {
			headers: {
				"x-api-key": "YTkVmzAlkk3IXsFv1YChX8Gyw3icf1i24XVEkrsw",
				"Content-Type":
					"application/x-www-form-urlencoded;charset=utf-8",
			},
			body: "text=" + content.replaceAll(" ", ""),
		};

		if (content.replaceAll(" ", "").includes("ㅈㄴ")) {
			openPopup();
			setClicked(false);
			return;
		}
		if (content.replaceAll(" ", "").includes("ㅂㅅ")) {
			openPopup();
			setClicked(false);
			return;
		}
		if (content.replaceAll(" ", "").includes("ㅁㅊ")) {
			openPopup();
			setClicked(false);
			return;
		}
		if (content.replaceAll(" ", "").includes("ㄱㅅㄲ")) {
			openPopup();
			setClicked(false);
			return;
		}
		if (content.replaceAll(" ", "").includes("ㅈㄹ")) {
			openPopup();
			setClicked(false);
			return;
		}

		try {
			const isNotSlang = await API.post(
				"apiFilter",
				"/filter/slang",
				apiInit,
			);

			if (!isNotSlang) {
				openPopup();
				setClicked(false);
				return;
			}

			const qs = queryString.parse(location.search);

			await API.graphql({
				query: mutations.createMessage,
				variables: {
					input: {
						region: 1,
						contentNumber: 2,
						type: qs.t,
						content: content,
					},
				},
			});

			setType(0);
			navigate("/card/processing");
		} catch (error) {
			await API.graphql({
				query: mutations.updateStage,
				variables: {
					input: {
						id: 1,
						state: "INIT",
					},
				},
			});

			console.log(error);

			setType(0);
			navigate("/error");
		}
	}

	function saveContent(event) {
		setContent(event.target.value);
	}

	useEffect(() => {
		const qs = queryString.parse(location.search);
		setType(qs.t);
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="card_container">
			<div className="card_writing">
				<div className="img">
					{type !== 0 ? (
						<img
							src={`/img/card/writing/${"card" + type}.png`}
							alt="camera_start_text"
							className="card_img"
						></img>
					) : (
						<></>
					)}
				</div>
				<div className="input">
					<textarea
						className="content_input"
						type="text"
						maxLength={40}
						placeholder={placeholder}
						value={content}
						onChange={saveContent}
						onFocus={() => {
							setPlaceholder("");
						}}
						onBlur={() => {
							window.scrollTo(0, 0);

							if (!content) {
								setPlaceholder(
									"여기를 눌러\n내용을 입력하세요.",
								);
							}
						}}
					></textarea>
					<div className="content_length">{content.length}/40</div>
					<div className="alert">
						주의 - 비방/협박 등의 메시지 작성 시 경찰서에 신고됩니다
					</div>
				</div>

				<div className="button">
					<img
						src={`/img/card/writing/send_${
							content ? "active" : "inactive"
						}.png`}
						alt="button"
						className="button_img"
						onClick={send}
					></img>
				</div>
				<div
					className="popup"
					onClick={closePopup}
					style={{
						display: `${popup ? "" : "none"}`,
						zIndex: `${popup ? 100 : -100}`,
					}}
				>
					<img
						src={`/img/card/writing/filter_popup.png`}
						alt="popup"
						className="popup_img"
					></img>
				</div>
			</div>
			<div
				className="dimmed"
				style={{
					display: `${popup ? "" : "none"}`,
					position: "fixed",
					top: "0px",
					left: "0px",
					width: "100%",
					height: "100%",
					zIndex: `${popup ? 1 : -100}`,
					opacity: "0.25",
					backgroundColor: "rgb(0, 0, 0)",
				}}
			></div>
		</div>
	);
}
