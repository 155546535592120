/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQr = /* GraphQL */ `
  query GetQr($id: ID!) {
    getQr(id: $id) {
      id
      content
      state
      createdAt
      updatedAt
    }
  }
`;
export const listQrs = /* GraphQL */ `
  query ListQrs($filter: ModelQrFilterInput, $limit: Int, $nextToken: String) {
    listQrs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        state
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      storageKey
      qr
      createdAt
      updatedAt
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storageKey
        qr
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      contentNumber
      state
      createdAt
      updatedAt
    }
  }
`;
export const listStages = /* GraphQL */ `
  query ListStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentNumber
        state
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      contentNumber
      region
      type
      from
      content
      to
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentNumber
        region
        type
        from
        content
        to
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
