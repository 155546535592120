import React, { useEffect } from "react";

import { Amplify, API, graphqlOperation } from "aws-amplify";

import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

export default function CardProcessing() {
	const navigate = useNavigate();
	const location = useLocation();

	function next() {
		navigate("/card/completed");
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		setTimeout(() => next(), 3000);
	}, []);

	return (
		<div className="card_container">
			<div className="card_processing">
				<div className="img">
					<img
						src="/img/card/processing/anim.gif"
						alt="processing_anim"
						className="anim_img"
						loop="infinite"
					></img>
				</div>
			</div>
		</div>
	);
}
