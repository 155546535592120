import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CameraStart from "./js/Camera/Start.js";
import CameraShot from "./js/Camera/Shot.js";
import CameraTimer from "./js/Camera/Timer.js";
import CameraConfirm from "./js/Camera/Confirm.js";
import CameraProcessing from "./js/Camera/Processing.js";
import CameraCompleted from "./js/Camera/Completed.js";

import CameraError from "./js/Camera/Error.js";

import CardTest from "./js/Card/Test.js";
import CardStart from "./js/Card/Start.js";
import CardSelect from "./js/Card/Select.js";
import CardWriting from "./js/Card/Writing.js";
import CardProcessing from "./js/Card/Processing.js";
import CardCompleted from "./js/Card/Completed.js";

import CardError from "./js/Card/Error.js";

import "./css/default.css";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route
						path="/camera/start/:qr"
						element={<CameraStart />}
					></Route>
					<Route path="/camera/shot" element={<CameraShot />}></Route>
					<Route
						path="/camera/timer"
						element={<CameraTimer />}
					></Route>
					<Route
						path="/camera/confirm"
						element={<CameraConfirm />}
					></Route>
					<Route
						path="/camera/processing"
						element={<CameraProcessing />}
					></Route>
					<Route
						path="/camera/completed"
						element={<CameraCompleted />}
					></Route>
					<Route
						path="/camera/error"
						element={<CameraError />}
					></Route>
					<Route path="/camera/*" element={<CameraError />}></Route>

					{/* <Route path="/card/test" element={<CardTest />}></Route> */}

					<Route path="/card/start" element={<CardStart />}></Route>
					<Route path="/card/select" element={<CardSelect />}></Route>
					<Route
						path="/card/writing"
						element={<CardWriting />}
					></Route>
					<Route
						path="/card/processing"
						element={<CardProcessing />}
					></Route>
					<Route
						path="/card/completed"
						element={<CardCompleted />}
					></Route>
					<Route path="/error" element={<CardError />} />
					<Route path="/*" element={<CardError />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
