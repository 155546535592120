import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { Amplify, API, graphqlOperation } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

export default function CardSelect() {
	const navigate = useNavigate();

	const [type, setType] = useState(1);
	const [clicked, setClicked] = useState(false);

	function select(value) {
		setType(value);
	}

	function next() {
		if (clicked) {
			return;
		}

		setClicked(true);

		navigate("/card/writing?t=" + type);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="card_container">
			<div className="card_select">
				<div className="card_area">
					<div className="card_box card_box_1">
						<div className="card card_1">
							<img
								src="/img/card/select/card1.png"
								alt="card_1_img"
								className="card_1_img"
								onClick={() => select(1)}
							></img>
						</div>
						{type === 1 ? (
							<div className="pick pick_1">
								<img
									src="/img/card/select/pick.png"
									alt="pick_1_img"
									className="pick_1_img"
								></img>
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="card_box card_box_2">
						<div className="card card_2">
							<img
								src="/img/card/select/card2.png"
								alt="card_2_img"
								className="card_2_img"
								onClick={() => select(2)}
							></img>
						</div>
						{type === 2 ? (
							<div className="pick pick_2">
								<img
									src="/img/card/select/pick.png"
									alt="pick_2_img"
									className="pick_2_img"
								></img>
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="card_box card_box_3">
						<div className="card card_3">
							<img
								src="/img/card/select/card3.png"
								alt="card_3_img"
								className="card_3_img"
								onClick={() => select(3)}
							></img>
						</div>
						{type === 3 ? (
							<div className="pick pick_3">
								<img
									src="/img/card/select/pick.png"
									alt="pick_3_img"
									className="pick_3_img"
								></img>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="button">
					<img
						src="/img/card/select/next.png"
						alt="button"
						className="button_img"
						onClick={next}
					></img>
				</div>
			</div>
		</div>
	);
}
