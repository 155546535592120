/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateQr = /* GraphQL */ `
  subscription OnCreateQr {
    onCreateQr {
      id
      content
      state
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateQr = /* GraphQL */ `
  subscription OnUpdateQr {
    onUpdateQr {
      id
      content
      state
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteQr = /* GraphQL */ `
  subscription OnDeleteQr {
    onDeleteQr {
      id
      content
      state
      createdAt
      updatedAt
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage {
    onCreateImage {
      id
      storageKey
      qr
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage {
    onUpdateImage {
      id
      storageKey
      qr
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage {
    onDeleteImage {
      id
      storageKey
      qr
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStage = /* GraphQL */ `
  subscription OnCreateStage {
    onCreateStage {
      id
      contentNumber
      state
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStage = /* GraphQL */ `
  subscription OnUpdateStage {
    onUpdateStage {
      id
      contentNumber
      state
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStage = /* GraphQL */ `
  subscription OnDeleteStage {
    onDeleteStage {
      id
      contentNumber
      state
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      contentNumber
      region
      type
      from
      content
      to
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      contentNumber
      region
      type
      from
      content
      to
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      contentNumber
      region
      type
      from
      content
      to
      createdAt
      updatedAt
    }
  }
`;
