import React, { useEffect } from "react";

import { Amplify, API, graphqlOperation } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

let sub;

export default function CameraTimer() {
	const navigate = useNavigate();
	const location = useLocation();

	async function fetchQr() {
		const response = await API.graphql(
			graphqlOperation(queries.getQr, { id: "1" }),
		);

		const activeQr = response.data.getQr.content;

		const qs = queryString.parse(location.search);

		if (activeQr !== qs.q) {
			navigate("/camera/error");
		}
	}

	async function next(storageKey) {
		const newQr = uuidv4();

		try {
			const response = await API.graphql(
				graphqlOperation(queries.getQr, { id: "1" }),
			);

			const activeQr = response.data.getQr.content;
			const qs = queryString.parse(location.search);

			if (activeQr !== qs.q) {
				navigate("/camera/error");
				return;
			}

			await API.graphql({
				query: mutations.updateQr,
				variables: {
					input: {
						id: 1,
						content: newQr,
					},
				},
			});

			await API.graphql({
				query: mutations.updateStage,
				variables: {
					input: {
						id: 1,
						state: "RESULT",
					},
				},
			});

			sub.unsubscribe();

			navigate("/camera/confirm?sk=" + storageKey + "&q=" + newQr);
		} catch (error) {
			await API.graphql({
				query: mutations.updateStage,
				variables: {
					input: {
						id: 1,
						state: "INIT",
					},
				},
			});

			console.log(error);

			navigate("/camera/error");
		}
	}

	async function initSubscriptions() {
		sub = await API.graphql(
			graphqlOperation(subscriptions.onCreateImage),
		).subscribe({
			next: ({ provider, value }) => {
				console.log(value.data.onCreateImage);
				next(
					value.data.onCreateImage.storageKey.replace("public/", ""),
				);
			},
			error: (error) => console.warn(error),
		});
	}

	async function init() {
		await fetchQr();

		await initSubscriptions();

		// const qs = queryString.parse(location.search);

		// if (qs.r === "false") {
		// 	await initSubscriptions();
		// }
	}

	useEffect(() => {
		init();
	}, []);

	return (
		<div className="camera_container">
			<div className="camera_timer">
				<div className="bg_top">
					<img
						src="/img/camera/bg_top.png"
						alt="bg_top"
						className="bg_top_img"
					></img>
				</div>
				<div className="text">
					<img
						src="/img/camera/timer/text.png"
						alt="camera_timer_text"
						className="text_img"
					></img>
				</div>
				<div className="bg_bottom">
					<img
						src="/img/camera/bg_bottom.png"
						alt="bg_bottom"
						className="bg_bottom_img"
					></img>
				</div>
			</div>
		</div>
	);
}
