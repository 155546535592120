import React, { useEffect } from "react";

export default function CardError() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	
	return (
		<div className="card_error_container">
			<div className="card_error">
				<div
					className="dimmed"
					style={{
						position: "fixed",
						top: "0px",
						left: "0px",
						width: "100%",
						height: "100%",
						opacity: "0.25",
						backgroundColor: "rgb(0, 0, 0)",
					}}
				></div>
				<div className="text">
					<img
						src="/img/error/card/card_text.png"
						alt="card_text"
						className="error_card_text_img"
					></img>
				</div>
				<div className="img">
					<img
						src="/img/error/img.png"
						alt="error_img"
						className="error_img"
					></img>
				</div>
			</div>
		</div>
	);
}
