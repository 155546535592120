/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQr = /* GraphQL */ `
  mutation CreateQr($input: CreateQrInput!, $condition: ModelQrConditionInput) {
    createQr(input: $input, condition: $condition) {
      id
      content
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateQr = /* GraphQL */ `
  mutation UpdateQr($input: UpdateQrInput!, $condition: ModelQrConditionInput) {
    updateQr(input: $input, condition: $condition) {
      id
      content
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteQr = /* GraphQL */ `
  mutation DeleteQr($input: DeleteQrInput!, $condition: ModelQrConditionInput) {
    deleteQr(input: $input, condition: $condition) {
      id
      content
      state
      createdAt
      updatedAt
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      storageKey
      qr
      createdAt
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      storageKey
      qr
      createdAt
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      storageKey
      qr
      createdAt
      updatedAt
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      contentNumber
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      contentNumber
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      contentNumber
      state
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      contentNumber
      region
      type
      from
      content
      to
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      contentNumber
      region
      type
      from
      content
      to
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      contentNumber
      region
      type
      from
      content
      to
      createdAt
      updatedAt
    }
  }
`;
