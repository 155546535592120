import React, { useEffect, useState } from "react";

import { Amplify, API, graphqlOperation } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

export default function CameraShot() {
	const navigate = useNavigate();
	const location = useLocation();

	const [clicked, setClicked] = useState(false);

	async function shot() {
		const newQr = uuidv4();

		if (clicked) {
			return;
		}

		setClicked(true);

		try {
			const response = await API.graphql(
				graphqlOperation(queries.getQr, { id: "1" }),
			);

			const activeQr = response.data.getQr.content;
			const qs = queryString.parse(location.search);

			if (activeQr !== qs.q) {
				navigate("/camera/error");
				return;
			}

			await API.graphql({
				query: mutations.updateQr,
				variables: {
					input: {
						id: 1,
						content: newQr,
					},
				},
			});

			await API.graphql({
				query: mutations.updateStage,
				variables: {
					input: {
						id: 1,
						state: "SHOT",
					},
				},
			});

			navigate("/camera/timer?q=" + newQr + "&r=" + qs.r);
		} catch (error) {
			await API.graphql({
				query: mutations.updateStage,
				variables: {
					input: {
						id: 1,
						state: "INIT",
					},
				},
			});

			console.log(error);

			navigate("/camera/error");
		}
	}

	async function init() {
		await fetchQr();
	}

	async function fetchQr() {
		const response = await API.graphql(
			graphqlOperation(queries.getQr, { id: "1" }),
		);

		const activeQr = response.data.getQr.content;

		const qs = queryString.parse(location.search);

		if (activeQr !== qs.q) {
			navigate("/camera/error");
		}
	}

	useEffect(() => {
		init();
	}, []);

	return (
		<div className="camera_container">
			<div className="camera_shot">
				<div className="bg_top">
					<img
						src="/img/camera/bg_top.png"
						alt="bg_top"
						className="bg_top_img"
					></img>
				</div>
				<div className="text">
					<img
						src="/img/camera/shot/text.png"
						alt="camera_start_text"
						className="text_img"
					></img>
				</div>
				<div className="button">
					<img
						src="/img/camera/shot/button.png"
						alt="bg_bottom"
						className="button_img"
						onClick={shot}
					></img>
				</div>
				<div className="bg_bottom">
					<img
						src="/img/camera/bg_bottom.png"
						alt="bg_bottom"
						className="bg_bottom_img"
					></img>
				</div>
			</div>
		</div>
	);
}
