import React, { useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { Amplify, API, graphqlOperation } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

export default function CardStart() {
	const navigate = useNavigate();

	function start() {
		navigate("/card/select");
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="card_container">
			<div className="card_start">
				<div className="img">
					<img
						src="/img/card/start/main.png"
						alt="main_img"
						className="main_img"
						onClick={start}
					></img>
				</div>
			</div>
		</div>
	);
}
