import React, { useEffect } from "react";

import { Amplify, API, graphqlOperation } from "aws-amplify";

import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

export default function CardCompleted() {
	const navigate = useNavigate();
	// const location = useLocation();

	function re() {
		navigate("/card/start");
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		setTimeout(() => re(), 3000);
	}, []);

	return (
		<div className="card_container">
			<div className="card_completed"></div>
		</div>
	);
}
