import React, { useEffect, useState } from "react";

import { Amplify, API, graphqlOperation, Storage } from "aws-amplify";

import { v4 as uuidv4 } from "uuid";

import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

export default function CameraCompleted() {
	const navigate = useNavigate();
	const location = useLocation();

	const [imageUrl, setImageUrl] = useState();

	async function fetchQr() {
		const response = await API.graphql(
			graphqlOperation(queries.getQr, { id: "1" }),
		);

		const activeQr = response.data.getQr.content;

		const qs = queryString.parse(location.search);

		if (activeQr !== qs.q) {
			navigate("/camera/error");
		}
	}

	async function init() {
		await fetchQr();

		const qs = queryString.parse(location.search);

		const imageUrl = await Storage.get(qs.sk);

		setImageUrl(imageUrl);
	}

	useEffect(() => {
		init();
	}, []);

	return (
		<div className="camera_container">
			<div className="camera_completed">
				<div className="bg_top">
					<img
						src="/img/camera/bg_top.png"
						alt="bg_top"
						className="bg_top_img"
					></img>
				</div>
				<div className="bg_bottom">
					<img
						src="/img/camera/bg_bottom.png"
						alt="bg_bottom"
						className="bg_bottom_img"
					></img>
				</div>
				<div
					className="dimmed"
					style={{
						position: "fixed",
						top: "0px",
						left: "0px",
						width: "100%",
						height: "100%",
						opacity: "0.25",
						backgroundColor: "rgb(0, 0, 0)",
					}}
				></div>
				<div className="text" style={{ zIndex: 1 }}>
					<img
						src="/img/camera/completed/text.png"
						alt="camera_completed_text"
						className="text_img"
						style={{ zIndex: 1 }}
					></img>
				</div>
				<div className="photo_box">
					<div className="photo_border">
						<img
							src="/img/camera/completed/photo_border.png"
							alt="photo_border"
							className="photo_border_img"
						></img>
					</div>
					<div className="photo">
						{imageUrl ? (
							<img
								src={imageUrl}
								alt="photo_img"
								className="photo_img"
							></img>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="bottom_img">
					<img
						src="/img/camera/completed/img.png"
						alt="img"
						className="img"
					></img>
				</div>
			</div>
		</div>
	);
}
