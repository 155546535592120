import React, { useEffect } from "react";

export default function CameraError() {
	return (
		<div className="camera_error_container">
			<div className="camera_error">
				<div className="bg_top">
					<img
						src="/img/camera/bg_top.png"
						alt="bg_top"
						className="bg_top_img"
					></img>
				</div>
				<div className="bg_bottom">
					<img
						src="/img/camera/bg_bottom.png"
						alt="bg_bottom"
						className="bg_bottom_img"
					></img>
				</div>
				<div
					className="dimmed"
					style={{
						position: "fixed",
						top: "0px",
						left: "0px",
						width: "100%",
						height: "100%",
						opacity: "0.6",
						backgroundColor: "rgb(0, 0, 0)",
					}}
				></div>
				<div className="text">
					<img
						src="/img/error/text.png"
						alt="error_text"
						className="error_text_img"
					></img>
				</div>
				<div className="img">
					<img
						src="/img/error/img.png"
						alt="error_img"
						className="error_img"
					></img>
				</div>
			</div>
		</div>
	);
}
